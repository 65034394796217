import React, { Component } from 'react'
import Checkmark from '../../components/icons/Checkmark'
import style from './kontakt.module.css'

export default class Form extends Component {
  constructor(props) {
    super(props)

    this.state = {
      sent: false,
      email: '',
      message: '',
    }

    this.submitForm = this.submitForm.bind(this)
    this.isFormValid = this.isFormValid.bind(this)
  }
  isFormValid() {
    const { email, message } = this.state
    return email !== '' && message !== ''
  }
  submitForm(e) {
    e.stopPropagation()
    e.preventDefault()

    const url = 'https://fewo-allgaier.de/mail'
    // const url = 'http://localhost:3000/mail'
    if (this.isFormValid()) {
      fetch(url, {
        method: 'POST',
        body: JSON.stringify(this.state),
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
        },
      })
      Promise.resolve().then(() => {
        this.setState({ sent: true, name: '', email: '', message: '' })
      })
    }
  }
  render() {
    const { sent, name, email, message } = this.state
    if (sent) {
      return (
        <div className={style.thanks}>
          <div>
            <Checkmark fill="green" />
          </div>
          <div>
            Vielen Dank für Ihre Nachricht. <br />
            Wir werden uns in Kürze bei Ihnen melden.
          </div>
        </div>
      )
    }
    return (
      <form
        // action="http://localhost:3000/mail"
        method="post"
        onSubmit={this.submitForm}
      >
        <input type="text" value={name || ''} onChange={e => this.setState({ name: e.target.value })} name="name" placeholder="Name" />
        <input type="email" required value={email || ''} onChange={e => this.setState({ email: e.target.value })} name="email" placeholder="E-Mail" />
        <textarea
          name="message"
          required
          value={message || ''}
          onChange={e => this.setState({ message: e.target.value })}
          placeholder="Nachricht"
          id=""
          cols="30"
          rows="10"
          style={{ width: '100%' }}
        />
        <button type="submit">Absenden</button>
      </form>
    )
  }
}
