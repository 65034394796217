import React from 'react'
import Layout from '../../layouts'
import Form from './form'

export default () => (
  <Layout>
    <div>
      <h2>Kontakt</h2>
      <p>
        Haben Sie noch Fragen oder Interesse an der Buchung unserer
        Ferienwohnung?
      </p>
      <p>
        Schreiben Sie uns einfach unter Angabe Ihres Namens und Ihrer
        E-Mail-Adresse eine Nachricht über dieses Kontaktformular, wir melden
        uns bei Ihnen:
      </p>
      <Form />
    </div>
  </Layout>
)
